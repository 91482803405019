<template>
  <div class="m-12 mx-auto flex flex-1 rounded lg:w-1/2 lg:border lg:shadow-lg">
    <div
      class="flex flex-1 flex-col justify-center px-4 py-10 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
    >
      <div class="w-128 mx-auto max-w-sm text-center lg:w-96">
        <div>
          <div class="flex items-center justify-center">
            <img class="h-14 w-auto" src="~/assets/logo192.png" alt="HomeKiq" />
            <span class="-ml-2.5 text-2xl font-semibold">OMEKIQ</span>
          </div>
          <h2
            class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900"
          >
            Sign in to your account
          </h2>
          <p class="mt-2 text-sm leading-6 text-gray-500">
            Not a member?
            {{ " " }}
            <span class="font-semibold"
              >We will create an account for free</span
            >
          </p>
        </div>

        <div class="mt-10">
          <div v-if="route.query.error || route.query.Error" class="mb-4">
            <AuthAlertBox :error="route.query.error || route.query.Error" />
          </div>

          <div class="mt-10">
            <form class="mb-4 space-y-3" action="#" method="POST">
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Email address</label
                >
                <div class="mt-2">
                  <input
                    v-model="email"
                    name="email"
                    placeholder="martha@example.com"
                    type="email"
                    autocomplete="email"
                    required
                    class="block w-full rounded-md border-0 px-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  @click.prevent="signInHandler({ email })"
                >
                  Sign in
                </button>
              </div>
            </form>

            <div class="relative">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full border-t border-gray-200" />
              </div>
              <div
                class="relative flex justify-center text-sm font-medium leading-6"
              >
                <span class="bg-white px-6 text-gray-700"
                  >Or continue with</span
                >
              </div>
            </div>

            <div class="mt-4">
              <a
                id="google"
                href="#"
                type="button"
                class="mb-2 inline-flex w-full items-center justify-center rounded-md border px-3 py-2.5 text-center text-sm font-medium focus:outline-none focus:ring-4"
                @click="signInWithGoogle()"
              >
                <svg
                  class="-ml-1 mr-2 h-4 w-4"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_13183_10121)">
                    <path
                      d="M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z"
                      fill="#3F83F8"
                    />
                    <path
                      d="M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z"
                      fill="#FBBC04"
                    />
                    <path
                      d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z"
                      fill="#EA4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_13183_10121">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Google
              </a>
            </div>
            <div class="mt-4">
              <button
                type="button"
                class="mb-2 mr-2 inline-flex w-full items-center justify-center rounded-md bg-[#050708] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#050708]/90 focus:ring-4 focus:ring-[#050708]/50"
                @click="signInWithApple()"
              >
                <svg
                  class="-ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="apple"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  ></path>
                </svg>
                Sign in with Apple
              </button>
            </div>
          </div>
          <div
            class="mt-16 cursor-pointer text-gray-500 hover:underline"
            @click.prevent="showDTermsDialog($event)"
          >
            Terms & Conditions
          </div>
        </div>
      </div>
    </div>
    <div class="relative hidden w-0 flex-1 lg:block">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="https://images.unsplash.com/photo-1570129477492-45c003edd2be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
        alt=""
      />
    </div>
    <ModalContainer v-if="showModal">
      <div class="p-4">
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-3 leading-6 text-gray-700">
            <p><TermsOfUse /></p>
          </div>
        </div>
        <div class="flex gap-2">
          <button
            type="button"
            class="text-md mt-8 w-full items-center gap-3 rounded-md border bg-white px-3 py-2 font-semibold text-gray-500 shadow hover:bg-gray-50"
            @click.prevent="showModal = false"
          >
            Close
          </button>
        </div>
      </div>
    </ModalContainer>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationTriangleIcon } from "@heroicons/vue/24/solid";
import { ref } from "vue";

const email = ref("");

const { signIn } = useAuth();
const route = useRoute();

definePageMeta({
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: "/",
  },
});

const signInHandler = async ({ email }: { email: string }) => {
  // @ts-ignore
  const { error, url } = await signIn("email", { email });

  if (error) {
    // Do your custom error handling here
    alert("You have made a terrible mistake while entering your credentials");
  } else {
    // No error, continue with the sign in, e.g., by following the returned redirect:
    return navigateTo(url, { replace: true });
  }
};

function signInWithGoogle() {
  if (window.hasOwnProperty("mobile")) {
    // @ts-ignore
    window.mobile.googleLogin();
  } else {
    signIn("google");
  }
}

function signInWithApple() {
  if (window.hasOwnProperty("mobile")) {
    // @ts-ignore
    window.mobile.appleLogin();
  } else {
    signIn("apple");
  }
}

const showModal = ref(false);

function showDTermsDialog(event: Event) {
  showModal.value = true;
  event.preventDefault();
}
</script>
